import {
  ADD_ADS_DICTIONARY,
  FETCH_ADS_DICTIONARY,
  addAdsDictionaryAction,
  fetchAdsDictionaryAction,
} from 'actions/dictionary/fetchAdsDictionaryAction'
import { Reducer } from 'redux'
import { defaultPromiseReducer } from 'reducers/system/defaultPromiseReducer'
import { definitions } from 'api/generated/masked_ads'

export interface DictionaryState {
  groups: definitions['AdTagGroup'][]
  tags: definitions['AdTag'][]
  whoIsSeekerOptions: { value: number; name: string }[]
  whoLookingForOptions: { value: number; name: string }[]
  locale: string | null
}

export const dictionaryReducer: Reducer<
  DictionaryState,
  | ReturnType<typeof fetchAdsDictionaryAction>
  | ReturnType<typeof addAdsDictionaryAction>
> = (
  state = {
    groups: [],
    locale: null,
    tags: [],
    whoIsSeekerOptions: [],
    whoLookingForOptions: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_ADS_DICTIONARY:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result) {
          return {
            groups: result.groupedTagList.groups,
            locale: action.locale,
            tags: result.groupedTagList.groups.reduce<definitions['AdTag'][]>(
              (acc, group) => [...acc, ...group.tags],
              []
            ),
            whoIsSeekerOptions: result.whoIsSeekerOptions.map(
              ({ value, title }) => ({ value, name: title })
            ),
            whoLookingForOptions: result.whoLookingForOptions.map(
              ({ value, title }) => ({ value, name: title })
            ),
          }
        }

        return state
      })

    case ADD_ADS_DICTIONARY:
      return {
        ...state,
        groups: action.dictionary.groupedTagList.groups,
        locale: action.locale,
        tags: action.dictionary.groupedTagList.groups.reduce<
          definitions['AdTag'][]
        >((acc, group) => [...acc, ...group.tags], []),
        whoIsSeekerOptions: action.dictionary.whoIsSeekerOptions.map(
          ({ value, title }) => ({ value, name: title })
        ),
        whoLookingForOptions: action.dictionary.whoLookingForOptions.map(
          ({ value, title }) => ({ value, name: title })
        ),
      }

    default:
      return state
  }
}
