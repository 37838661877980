import { NodeHeaders } from 'server/NodeHeaders'
import { definitions } from 'api/generated/masked_ads'
import { putApi } from 'api/fetchApi'

export const saveAnnounceFormApi = (
  form: definitions['MaskedAdSearchPreferencesPutBodyRequest'],
  headers?: NodeHeaders
) => {
  return putApi<
    { result: true },
    definitions['MaskedAdSearchPreferencesPutBodyRequest']
  >(
    '/ad-search-preferences',
    {
      whoIsSeeker: form.whoIsSeeker,
      whoLookingFor: form.whoLookingFor,
      minAge: form.minAge,
      maxAge: form.maxAge,
      location: form.location,
      tags: form.tags,
    },
    {
      headers: { ...headers },
    }
  )
}
