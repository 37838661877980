import { ApiResult, allPaths, fetchApi } from 'api/fetchApi'

/**
 * Пока нет доки, делаю так
 * Надо попросить разработчика выложить доку и сгенерить.
 * https://youtrack.mamba.ru/issue/MSKD-860
 */
export interface PaymentExternalService {
  orderId: 'UUID'
}

export const fetchCurrentOrderApi = (): Promise<
  ApiResult<PaymentExternalService>
> => fetchApi<PaymentExternalService>('/order/current' as allPaths)
