import React, { FC } from 'react'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { DefaultLoader } from 'components/presentational/loader/DefaultLoader'
import { useRedirectLink } from 'hooks/useRedirectLink'

export const AnnounceListRedirect: FC = () => {
  const redirectUrl = useRedirectLink()

  if (redirectUrl) {
    return <RedirectWithStatus uid="boarding" status={301} to={redirectUrl} />
  }

  return <DefaultLoader />
}
