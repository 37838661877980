import { fetchAdsDictionaryAction } from 'actions/dictionary/fetchAdsDictionaryAction'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const ChangeDictionaryIfNeeded = () => {
  const dispatch = useDispatch()
  const { language, locale } = useShallowEqualSelector(
    ({ system: { language }, dictionary: { locale } }) => ({
      language,
      locale,
    })
  )

  useEffect(() => {
    if (language && language.code && locale && language.code !== locale) {
      dispatch(fetchAdsDictionaryAction(language.code))
    }
  }, [dispatch, language, locale])

  return null
}
