import { GenderIndex } from 'constants/announces/gender'
import { Paths } from 'components/components.paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const createUrlUseParameters = (
  locale: string,
  gender1: number,
  gender2: number,
  ageFrom: number,
  ageTo: number,
  tags: string,
  location: string
): string => {
  const _location = location.split('_').join('-')

  /**
   * Здесь мы меняем порядок пола исходя из
   * https://redmine.mamba.ru/issues/119171 и
   * components.paths.ts Paths.list
   */
  return mergeAllUrls(
    locale,
    Paths.baseList,
    `${GenderIndex[gender2]}-${GenderIndex[gender1]}`,
    `${ageFrom}-${ageTo}`,
    tags,
    _location
  )
}
