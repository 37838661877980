import { allPaths, fetchApi } from 'api/fetchApi'

export interface FetchPaymentResponse {
  paymentId: string // "UUID"
  paymentTypes: [
    {
      aggregator: string // "PaySelection",
      paymentType: string // "BankCards",
      paymentFlow: string // "link",
      data: string // "https://widget2.payselection.com/pay/url"
    }
  ]
}

export const fetchPaymentApi = (orderId: string) =>
  fetchApi<FetchPaymentResponse>(`/billing/payments/${orderId}` as allPaths)
