import { createUrlUseParameters } from 'functions/createUrlUseParameters'
import { serializeTags } from 'functions/serializeTags'
import { useLocale } from 'hooks/useLocale'
import { useMemo } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const useRedirectLink = (): string | null => {
  const { defaultForm, formLoaded } = useShallowEqualSelector(
    ({ announceForm: { defaultForm, formLoaded } }) => ({
      defaultForm,
      formLoaded,
    })
  )

  const locale = useLocale()

  const { whoIsSeeker, whoLookingFor, minAge, maxAge, location, tags } =
    defaultForm

  const _tagIds = serializeTags(tags)

  return useMemo(() => {
    if (
      formLoaded &&
      whoIsSeeker &&
      whoLookingFor &&
      minAge &&
      maxAge &&
      location &&
      Boolean(_tagIds) &&
      location.location
    ) {
      return createUrlUseParameters(
        locale,
        whoIsSeeker,
        whoLookingFor,
        minAge,
        maxAge,
        _tagIds,
        location.location
      )
    }

    return null
  }, [
    formLoaded,
    locale,
    location,
    maxAge,
    minAge,
    _tagIds,
    whoIsSeeker,
    whoLookingFor,
  ])
}
