import {
  announceAdsTagsPath,
  announceListLocation,
} from 'components/components.paths'
import { matchPath, useLocation } from 'react-router'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLocale } from 'hooks/useLocale'
import { useMemo } from 'react'

export const useBaseUrlWithoutPage = (): {
  params: Record<string, string>
  url: string
} | null => {
  const location = useLocation()
  const locale = useLocale()

  return useMemo(() => {
    const pathWithLocale = mergeAllUrls(
      locale,
      announceAdsTagsPath,
      announceListLocation
    )

    const match = matchPath(location.pathname, {
      path: pathWithLocale,
      exact: false,
      strict: false,
    })

    if (match) {
      return {
        params: match.params,
        url: match.url,
      }
    }

    return null
  }, [location, locale])
}
