import { Action } from 'redux'
import {
  AnnounceFormUpdateWithLocation,
  AnnounceFormUpdateWithoutLocation,
  AnnounceTemporaryParams,
} from 'reducers/announce/announce.types'
import { AppDispatchNext } from 'actions/actions.typesNext'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { FieldName, FieldValueTypes } from 'actions/announce/announceForm.types'
import { NodeHeaders } from 'server/NodeHeaders'
import { definitions } from 'api/generated/masked_ads'
import { fetchAdsSettingFormApi } from 'api/announce/fetchAdsSettingsFormApi'
import { saveAnnounceFormApi } from 'api/announce/saveAnnounceFormApi'

export const UPDATE_FULL_FORM_FROM_SERVER = 'UPDATE_FULL_FORM_FROM_SERVER'

interface UpdateFormWithoutRequestAction {
  type: typeof UPDATE_FULL_FORM_FROM_SERVER
  form: AnnounceFormUpdateWithLocation
}

export const updateFormWithoutRequestAction = (
  form: AnnounceFormUpdateWithLocation
): UpdateFormWithoutRequestAction => ({
  type: UPDATE_FULL_FORM_FROM_SERVER,
  form,
})

export const UPDATE_FORM_WITHOUT_LOCATION =
  'UPDATE_FORM_WITHOUT_LOCATION' as const

interface UpdateFormWithoutLocationAction {
  type: typeof UPDATE_FORM_WITHOUT_LOCATION
  form: AnnounceFormUpdateWithoutLocation
}

export const updateFormWithoutLocationAction = (
  form: AnnounceFormUpdateWithoutLocation
): UpdateFormWithoutLocationAction => ({
  type: UPDATE_FORM_WITHOUT_LOCATION,
  form,
})

export const UPDATE_TEMPORARY_FORM = 'UPDATE_TEMPORARY_FORM' as const

interface UpdateFormTemporaryAction {
  type: typeof UPDATE_TEMPORARY_FORM
  form: AnnounceTemporaryParams
}

export const updateFormTemporaryAction = (
  form: AnnounceTemporaryParams
): UpdateFormTemporaryAction => ({
  type: UPDATE_TEMPORARY_FORM,
  form,
})

export const UPDATE_ANNOUNCE_FORM = 'UPDATE_ANNOUNCE_FORM'

interface UpdateAnnounceFormAction {
  type: typeof UPDATE_ANNOUNCE_FORM
  name: FieldName
  value: FieldValueTypes
}

export const updateAnnounceFormAction = (
  name: FieldName,
  value: FieldValueTypes
): UpdateAnnounceFormAction => ({
  name,
  value,
  type: UPDATE_ANNOUNCE_FORM,
})

export const extractSavingAnnounceFormAction =
  (): AsyncThunkAction<Action<string> | Promise<void>> =>
  (dispatch, getState) => {
    const { whoIsSeeker, whoLookingFor, minAge, maxAge, location, tags } =
      getState().announceForm.changingForm

    const _tags = tags.map(({ value }) => value)

    if (
      location &&
      location.location &&
      whoIsSeeker &&
      whoLookingFor &&
      minAge &&
      maxAge &&
      tags
    ) {
      return dispatch(
        saveAnnounceFormAction({
          location: location.location,
          whoIsSeeker,
          whoLookingFor,
          minAge,
          maxAge,
          tags: _tags,
        })
      )
    }

    return Promise.resolve()
  }

export const SAVE_ANNOUNCE_FORM = 'SAVE_ANNOUNCE_FORM'

export interface SaveAnnounceFormAction
  extends AsyncAction<{ result: string }> {
  type: typeof SAVE_ANNOUNCE_FORM
}

export const saveAnnounceFormAction = (
  form: definitions['MaskedAdSearchPreferencesPutBodyRequest'],
  headers?: NodeHeaders
) => ({
  type: SAVE_ANNOUNCE_FORM,
  promise: () => saveAnnounceFormApi(form, headers),
})

export const MARKED_INVALID_PARAMS = 'MARKED_INVALID_PARAMS'

interface MarkedInvalidParamsAction {
  type: typeof MARKED_INVALID_PARAMS
}

export const markedInvalidParamsAction = () => ({
  type: MARKED_INVALID_PARAMS,
})

export const RESET_INVALID_PARAMS = 'RESET_INVALID_PARAMS'

interface ResetInvalidParamsAction {
  type: typeof RESET_INVALID_PARAMS
}

export const resetInvalidParamsAction = (): ResetInvalidParamsAction => ({
  type: RESET_INVALID_PARAMS,
})

export const RESET_SETTINGS_FORM = 'RESET_SETTINGS_FORM'

interface ResetSettingsFormAction {
  type: typeof RESET_SETTINGS_FORM
}

export const resetSettingsFormAction = () => ({
  type: RESET_SETTINGS_FORM,
})

export const FETCH_AD_SETTINGS_FORM = 'FETCH_AD_SETTINGS_FORM' as const

interface FetchAdSettingsFormAction
  extends AsyncAction<definitions['AdSearchPreferences']> {
  type: typeof FETCH_AD_SETTINGS_FORM
}

export const fetchAdsSettingsFormPlainAction = (
  headers?: NodeHeaders
): FetchAdSettingsFormAction => ({
  type: FETCH_AD_SETTINGS_FORM,
  promise: () => fetchAdsSettingFormApi(headers),
})

export const fetchAdsSettingsFormAction =
  (headers?: NodeHeaders) => (dispatch: AppDispatchNext) => {
    return dispatch(fetchAdsSettingsFormPlainAction(headers))
  }

export const FETCH_ADDITIONAL_ANNOUNCE_FORM_TAGS =
  'FETCH_ADDITIONAL_ANNOUNCE_FORM_TAGS' as const

interface FetchAdditionalAnnounceFormTagsAction
  extends AsyncAction<definitions['AdSearchPreferences']> {
  type: typeof FETCH_ADDITIONAL_ANNOUNCE_FORM_TAGS
}

export const fetchAdditionalAnnounceFormTagsPlainAction = (
  headers?: NodeHeaders
): FetchAdditionalAnnounceFormTagsAction => ({
  type: FETCH_ADDITIONAL_ANNOUNCE_FORM_TAGS,
  promise: () => fetchAdsSettingFormApi(headers),
})

export const fetchAdditionalAnnounceFormTagsAction =
  (headers?: NodeHeaders) => (dispatch: AppDispatchNext) => {
    return dispatch(fetchAdditionalAnnounceFormTagsPlainAction(headers))
  }

export const UPDATE_FORM_WITHOUT_REQUEST_WITHOUT_TAGS =
  'UPDATE_FORM_WITHOUT_REQUEST_WITHOUT_TAGS' as const

interface UpdateFormWithoutRequestWithoutTagsAction {
  type: typeof UPDATE_FORM_WITHOUT_REQUEST_WITHOUT_TAGS
  form: AnnounceFormUpdateWithLocation
}

export const updateFormWithoutRequestWithoutTagsAction =
  (form: AnnounceFormUpdateWithLocation) => (dispatch: AppDispatchNext) => {
    const _form = {
      whoIsSeeker: form.whoAmI,
      whoLookingFor: form.lookingFor,
      minAge: form.ageFrom,
      maxAge: form.ageTo,
      location: form.location,
    }

    return dispatch({
      type: UPDATE_FORM_WITHOUT_REQUEST_WITHOUT_TAGS,
      form: _form,
    })
  }

export type AnnounceSettingsFormTypes =
  | UpdateAnnounceFormAction
  | FetchAdSettingsFormAction
  | SaveAnnounceFormAction
  | UpdateFormWithoutRequestAction
  | MarkedInvalidParamsAction
  | ResetSettingsFormAction
  | ResetInvalidParamsAction
  | UpdateFormWithoutLocationAction
  | UpdateFormTemporaryAction
  | FetchAdditionalAnnounceFormTagsAction
  | UpdateFormWithoutRequestWithoutTagsAction
