import { AnnounceListFindApi } from 'reducers/announce/announce.types'
import { ApiResult, fetchQueryApi } from 'api/fetchApi'
import { NodeHeaders } from 'server/NodeHeaders'
import { convertLocation } from 'functions/convertLocation'
import { definitions, operations } from 'api/generated/masked_ads'

export const limit = 20

export const fetchAdsAnnounceListApi = (
  params: AnnounceListFindApi,
  headers?: NodeHeaders
): Promise<ApiResult<definitions['AdList']>> => {
  const _offset = params.offset || 0

  const _location = convertLocation(params.location)

  const parameters = {
    whoIs: params.whoIsSeeker,
    whoLooking: params.whoLookingFor,
    minAge: params.minAge,
    maxAge: params.maxAge,
    location: _location,

    tags: params.tags.map((tag) => tag),
    offset: _offset,
    limit,
  }

  return fetchQueryApi<
    definitions['AdList'],
    operations['masked_ads_search_by_params_getListBySearchParams']['parameters']['query']
  >('/ads/search-by-params', parameters, {
    headers: { ...headers },
  })
}
