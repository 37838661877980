import React from 'react'
import { App } from 'components/App'
import { Authorization } from 'components/system/Authorization'
import { ChangeDictionaryIfNeeded } from 'components/block/ChangeDictionaryIfNeeded/ChangeDictionaryIfNeeded'
import { ConnectedRouter } from 'connected-react-router'
import { ErrorRedirect } from 'components/page/notice/error/ErrorRedirect'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { RedirectConfirmAge } from 'components/page/notice/confirmAges/RedirectConfirmAge'
import { ThemeContext } from 'hooks/useTheme'
import { createLocalesRegExp } from 'constants/locale'
import { fixForReactIntl } from 'functions/locale'
import { history, store } from './configureClientStore'

// #_=_ приходит после верификации через фейсбук и приводит в разнице при запуске роутера
// в итоге добавляется событие PUSH, которое ломает навигацию назад
if (location.hash === '_=_') {
  location.hash = ''
}

// history.listen((location, action) => {
//   // location is an object like window.location
//   console.info('listen', action, location.pathname, location.state)
// })

export const InternalStart = () => {
  const {
    system: { language, languages },
  } = store.getState()

  return (
    <Provider store={store}>
      <IntlProvider
        locale={fixForReactIntl(language?.code as string)}
        messages={window.__MESSAGES__}
        defaultLocale={fixForReactIntl(language?.code as string)}
      >
        <ConnectedRouter history={history}>
          <ThemeContext.Provider value={window.__THEME__}>
            <App
              value={{
                store,
                history,
                localesRegExp: createLocalesRegExp(languages),
              }}
            />
            <RedirectConfirmAge />
            <Authorization />
            <ErrorRedirect />
            <ChangeDictionaryIfNeeded />
          </ThemeContext.Provider>
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  )
}
