import { NodeHeaders } from 'server/NodeHeaders'
import { definitions } from 'api/generated/masked_ads'
import { fetchApi } from 'api/fetchApi'

export const fetchAdsSettingFormApi = (headers?: NodeHeaders) => {
  return fetchApi<definitions['AdSearchPreferences']>(
    '/ad-search-preferences',
    {
      headers: { ...headers },
    }
  )
}
