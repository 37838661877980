import { Api6Error } from 'api/api.types'
import { AsyncThunkAction } from 'actions/actions.types'
import { Paths } from 'components/components.paths'
import { fetchCurrentOrderApi } from 'api/billing/fetchCurrentOrderApi'
import { fetchPaymentApi } from 'api/billing/fetchPaymentApi'
import { fullPageReload } from 'functions/fullPageReload'
import { replace } from 'actions/system/router'

export const FETCH_CURRENT_PAYMENT = 'FETCH_CURRENT_PAYMENT' as const

export const fetchCurrentOrderAction =
  (): AsyncThunkAction<Promise<unknown>> => async (dispatch) => {
    return dispatch({
      type: FETCH_CURRENT_PAYMENT,
      promise: async () => {
        const response = await fetchCurrentOrderApi()

        if (
          response.status &&
          [200, 201, 204].includes(response.status) &&
          response.result
        ) {
          const paymentResponse = await fetchPaymentApi(
            response.result?.orderId
          )

          if (
            paymentResponse.result &&
            paymentResponse.result.paymentTypes[0]
          ) {
            const { paymentFlow, data } = paymentResponse.result.paymentTypes[0]

            if (paymentFlow === 'link' && data) {
              return fullPageReload(data)
            }
          }
        } else {
          const _error = response.result as unknown as Api6Error

          if (_error && _error.code === 'service_order_not_found') {
            dispatch(replace(Paths.notFound))
            console.error('service order not found')
          }
        }

        return response
      },
    })
  }
