export const fullPageReload = (url?: string, replace?: boolean): void => {
  if (!process.env.browser) {
    throw new Error(`fullPageReload is client only: ${url}`)
  }

  if (url && replace) {
    window.location.replace(url)
  } else if (url) {
    window.location.assign(url)
  } else {
    window.location.reload()
  }
}
